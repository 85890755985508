@import "@/assets/scss/main.scss";

.btn-link,
.btn-primary {
    background: $elsi-color-blue;
    color: white;

    &:hover {
        background: darken($elsi-color-blue, 8);
    }
}

.btn-secondary {
    background: $elsi-color-dunkelgrau;
    color: $elsi-color-schrift-normal;

    &:hover {
        background: darken($elsi-color-dunkelgrau, 8);
    }
}

button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 32px;
    gap: 12px;

    height: 47px;
    border-radius: 3px;

    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 100%;
    color: #FFFFFF;
    text-decoration: none;
    border: 0 none;

    .b-icon.bi,
    .btn .b-icon.bi {
        font-size: inherit;
    }

}

.full-width {
    width: 100%;
}
